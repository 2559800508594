import React from 'react';

const CircleBlue = ({parentRef}) => {
    console.log(parentRef)
    return (
        <svg ref={parentRef} width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="lightblue" />
        </svg>
    );
};

export default CircleBlue;