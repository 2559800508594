import React, { useRef, useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CircleBlue from '../components/Shapes/CircleBlue';

const POC = () => {
    let circleItem = useRef(null)


    useEffect(() => {
        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }
    }, [])

    useEffect(() => {

    })

    return (
        <div>
            <h1>Proof of concept</h1>
            <CircleBlue parentRef={el => { circleItem = el }} />
        </div>
    );
};

export default POC;